<template>
  <div id="app">
    <cookie-law class="p-0 bg-dialog">
      <div slot-scope="props" class="text-center d-flex align-items-start flex-column vh-100 m-0 p-0">
        <div class="mt-auto bg-white p-4 vw-100">
          <h5 class="text-primary">{{ $t('cookie_permission') }}</h5>
          <b-button class="mt-4 px-4" pill variant="primary" @click="props.accept">{{ $t('accep_cookie') }}</b-button>
        </div>
      </div>
    </cookie-law>
    <messenger></messenger>
    <component :is="layout"></component>
  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
import LayoutFull from '@/layouts/LayoutFull.vue'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import LayoutWithPhone from './layouts/LayoutWithPhone.vue'
import Cookies from 'js-cookie'

import Messenger from './library/Messenger.vue'

export default {
  name: 'App',
  components: {
    CookieLaw,
    LayoutFull,
    LayoutDefault,
    LayoutWithPhone,
    Messenger,
  },
  data() {
    return {
      // message: 'User is on the page',
    }
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'default') return 'layout-default'
      else if (this.$route.meta.layout === 'phone') return 'layout-with-phone'
      return 'layout-full'
    },
  },
  beforeCreate() {
    if (process.env.VUE_APP_ENVIROMENT !== 'local') {
      this.$OneSignal.showSlidedownPrompt()
      this.$OneSignal.getUserId(function (userId) {
        Cookies.set('onesignal_user_id', userId)
      })
    }
  },
  mounted() {
    // Detect page unload
    // window.addEventListener('beforeunload', this.handleBeforeUnload)

    // Detect visibility change
    document.addEventListener('visibilitychange', this.handleVisibilityChange)

    // Detect window blur/focus
    window.addEventListener('blur', this.handleBlur)
    window.addEventListener('focus', this.handleFocus)

    // Start idle time detection
    window.addEventListener('mousemove', this.resetIdleTime)
    window.addEventListener('keypress', this.resetIdleTime)
  },
  beforeDestroy() {
    // Clean up event listeners
    // window.removeEventListener('beforeunload', this.handleBeforeUnload)
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
    window.removeEventListener('blur', this.handleBlur)
    window.removeEventListener('focus', this.handleFocus)

    // Clear idle detection
    clearInterval(this.idleInterval)
    window.removeEventListener('mousemove', this.resetIdleTime)
    window.removeEventListener('keypress', this.resetIdleTime)
  },
  methods: {
    // 1. Detect Page Unload (beforeunload)
    // handleBeforeUnload(e) {
    //   e.preventDefault()
    //   e.returnValue = '' // Custom message is no longer supported, but this prevents default behavior
    //   // console.log('User is leaving the page.')
    // },

    // 2. Detect Tab Visibility Change (visibilitychange)
    handleVisibilityChange() {
      if (document.visibilityState === 'hidden') {
        // this.message = 'User has left the tab.'
      } else {
        // this.message = 'User is back on the tab.'
        this.$store.dispatch('auth/updateCookiesTime')
      }
    },

    // 3. Detect Window Blur and Focus (blur, focus)
    handleBlur() {
      // this.message = 'User has left the window.'
    },
    handleFocus() {
      // this.message = 'User is active on the window.'
      this.$store.dispatch('auth/updateCookiesTime')
    },

    // 4. Idle Time Detection (Inactivity)
    resetIdleTime() {
      // this.message = 'User is active.'
      this.$store.dispatch('auth/updateCookiesTime')
    },
  },
}
</script>

<style></style>
