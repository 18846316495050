import * as types from '../mutation-types'
import Cookies from 'js-cookie'
// state
export const state = {
  // loanState: 'pending', //pending, pre_approve, appointment_done, approve
  loanState: Cookies.get('loanState') ?? 'pending',
}

// getters
export const getters = {
  loanState: (state) => state.loanState,
}

// mutations
export const mutations = {
  [types.SET_LOANSTATE](state, { loanState }) {
    state.loanState = loanState
  },
}

// actions
export const actions = {
  setLoanState({ commit }, { loanState }) {
    commit(types.SET_LOANSTATE, { loanState })
    Cookies.set('loanState', loanState, { expires: 365 })
  },
}
