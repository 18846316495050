export default function loanform({ to, next, store }) {
  const loanSubmitted = store.getters['auth/loanSubmitted']

  const routeName = to.name

  if ((loanSubmitted === true || loanSubmitted === 'true') && routeName !== 'home') {
    return next({ name: 'home' })
  }

  return next()
}
