export default function requiredDocuments({ to, next, store }) {
  const loanFormState = store.getters['auth/loanFormState']
  const routeName = to.name

  if (loanFormState !== 'customer_information') {
    if ((loanFormState === null || loanFormState === 'null') && routeName !== 'qr-scan') {
      return next({ name: 'qr-scan' })
    } else if (loanFormState === 'qr_scan' && routeName !== 'customer-information') {
      return next({ name: 'customer-information' })
    } else {
      return next()
    }
  }
  return next()
}
