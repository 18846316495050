import Cookies from 'js-cookie'
import * as types from '../mutation-types'

// state
export const state = {
  token: Cookies.get('token') ?? null,
  userId: Cookies.get('userId') ?? null,
  loanSubmitted: Cookies.get('loanSubmitted') ?? null,
  loanFormState: Cookies.get('loanFormState') ?? null,
  active: Cookies.get('active') ?? null,
  authToken: Cookies.get('authToken') ?? null,
  rejectCode: Cookies.get('rejectCode') ?? null,
}

// getters
export const getters = {
  token: (state) => state.token,
  userId: (state) => state.userId,
  loanSubmitted: (state) => state.loanSubmitted,
  loanFormState: (state) => state.loanFormState,
  active: (state) => state.active,
  authToken: (state) => state.authToken,
  rejectCode: (state) => state.rejectCode,
}

// mutations
export const mutations = {
  [types.SET_TOKEN](state, { token }) {
    state.token = token
  },
  [types.SET_USERID](state, { userId }) {
    state.userId = userId
  },
  [types.LOGOUT](state) {
    state.userId = null
    state.token = null
    state.loanFormState = null
    state.loanSubmitted = null
    state.active = null
    state.authToken = null
    state.rejectCode = null
    Cookies.remove('token')
    Cookies.remove('userId')
    Cookies.remove('phone_number')
    Cookies.remove('loanSubmitted')
    Cookies.remove('loanFormState')
    Cookies.remove('active')
    Cookies.remove('authToken')
    Cookies.remove('rejectCode')
  },
  [types.SET_LOAN_SUBMITTED](state, { loanSubmitted }) {
    state.loanSubmitted = loanSubmitted
  },
  [types.SET_LOAN_FORM_STATE](state, { loanFormState }) {
    state.loanFormState = loanFormState
  },
  [types.SET_ACTIVE](state, { active }) {
    state.active = active
  },
  [types.SET_AUTH_TOKEN](state, { authToken }) {
    state.authToken = authToken
  },
  [types.SET_REJECT_CODE](state, { rejectCode }) {
    state.rejectCode = rejectCode
  },
  [types.UPDATE_COOKIES_TIME](state, { dispatch }) {
    if (Cookies.get('token')) {
      dispatch('auth/setToken', { token: Cookies.get('token') ?? null }, { root: true })
      dispatch('auth/setUserId', { userId: Cookies.get('userId') ?? null }, { root: true })
      dispatch('auth/setLoanSubmitted', { loanSubmitted: Cookies.get('loanSubmitted') ?? null }, { root: true })
      dispatch('auth/setLoanFormState', { loanFormState: Cookies.get('loanFormState') ?? null }, { root: true })
      dispatch('auth/setRejectCode', { rejectCode: Cookies.get('rejectCode') ?? null }, { root: true })
      dispatch('auth/setActive', { active: Cookies.get('active') ?? null }, { root: true })
      dispatch('auth/setAuthToken', { authToken: Cookies.get('authToken') ?? null }, { root: true })
    }
  },
}

// actions
export const actions = {
  logout({ commit }) {
    commit(types.LOGOUT)
  },
  setToken({ commit }, { token }) {
    commit(types.SET_TOKEN, { token })
    Cookies.set('token', token, { expires: 10 / (24 * 60) }) // expire time 10 min
  },
  setUserId({ commit }, { userId }) {
    commit(types.SET_USERID, { userId })
    Cookies.set('userId', userId, { expires: 10 / (24 * 60) }) // expire time 10 min
  },
  setLoanSubmitted({ commit }, { loanSubmitted }) {
    commit(types.SET_LOAN_SUBMITTED, { loanSubmitted })
    Cookies.set('loanSubmitted', loanSubmitted, { expires: 10 / (24 * 60) }) // expire time 10 min
  },
  setLoanFormState({ commit }, { loanFormState }) {
    commit(types.SET_LOAN_FORM_STATE, { loanFormState })
    Cookies.set('loanFormState', loanFormState, { expires: 10 / (24 * 60) }) // expire time 10 min
  },
  setActive({ commit }, { active }) {
    commit(types.SET_ACTIVE, { active })
    Cookies.set('active', active, { expires: 10 / (24 * 60) }) // expire time 10 min
  },
  setAuthToken({ commit }, { authToken }) {
    commit(types.SET_AUTH_TOKEN, { authToken })
    Cookies.set('authToken', authToken, { expires: 10 / (24 * 60) }) // expire time 10 min
  },
  setRejectCode({ commit }, { rejectCode }) {
    commit(types.SET_REJECT_CODE, { rejectCode })
    Cookies.set('rejectCode', rejectCode, { expires: 10 / (24 * 60) }) // expire time 10 min
  },
  updateCookiesTime({ commit, dispatch }) {
    commit(types.UPDATE_COOKIES_TIME, { dispatch })
  },
}
