import Cookies from 'js-cookie'
import * as types from '../mutation-types'

function initialState() {
  return {
    name: null,
    phone: Cookies.get('phone_number'),
    dob: null,
    provinceWork: null,
    provinceResident: null,
    incomeType: null,
    salary: null,
    socialMedia: null,
    accept: false,
    pincode: null,
    otp: null,
  }
}

// state
export const state = {
  name: null,
  phone: Cookies.get('phone_number'),
  dob: null,
  provinceWork: null,
  provinceResident: null,
  incomeType: null,
  salary: null,
  socialMedia: null,
  accept: false,
  pincode: null,
  otp: null,
  initialState,
}

// getters
export const getters = {
  name: (state) => state.name,
  phone: (state) => state.phone,
  dob: (state) => state.dob,
  provinceWork: (state) => state.provinceWork,
  provinceResident: (state) => state.provinceResident,
  incomeType: (state) => state.incomeType,
  salary: (state) => state.salary,
  socialMedia: (state) => state.socialMedia,
  accept: (state) => state.accept,
  pincode: (state) => state.pincode,
  otp: (state) => state.otp,
}

// mutations
export const mutations = {
  [types.SAVE_REGISTER_INFO](state, { payload }) {
    state.name = payload.name
    state.phone = payload.phone
    state.dob = payload.dob
    state.provinceWork = payload.provinceWork
    state.provinceResident = payload.provinceResident
    state.incomeType = payload.incomeType
    state.salary = payload.salary
    state.socialMedia = payload.socialMedia
    state.accept = payload.accept
  },
  [types.SAVE_OTP_CODE](state, { otpCode }) {
    state.otp = otpCode
  },
  [types.SAVE_PINCODE](state, { pincode }) {
    state.pincode = pincode
  },
  [types.REGISTER_RESET](state) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },
}

// actions
export const actions = {
  saveRegisterInfo({ commit }, { payload }) {
    commit(types.SAVE_REGISTER_INFO, { payload })
    Cookies.set('phone_number', payload.phone, { expires: 365 })
  },
  saveOtpCode({ commit }, { otpCode }) {
    commit(types.SAVE_OTP_CODE, { otpCode })
  },
  savePincode({ commit }, { pincode }) {
    commit(types.SAVE_PINCODE, { pincode })
  },
  reset({ commit }) {
    commit(types.REGISTER_RESET)
  },
}
