import Cookies from 'js-cookie'
import * as types from '../mutation-types'
import i18n from '@/locales/i18n.js'

// state
export const state = {
  locale: Cookies.get('locale') ?? 'mm',
}

// getters
export const getters = {
  locale: (state) => state.locale,
}

// mutations
export const mutations = {
  [types.SET_LOCALE](state, { locale }) {
    state.locale = locale
  },
}

// actions
export const actions = {
  setLocale({ commit }, { locale }) {
    commit(types.SET_LOCALE, { locale })
    Cookies.set('locale', locale, { expires: 365 })
    i18n.locale = locale
  },
}
