<template>
  <div>
    <Header></Header>
    <div style="min-height: 100vh">
      <router-view />
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
export default {
  components: { Header, Footer },
}
</script>

<style></style>
