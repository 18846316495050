import Cookies from 'js-cookie'
import * as types from '../mutation-types'

// state
export const state = {
  count: Cookies.get('count'),
}

// getters
export const getters = {
  count: (state) => state.count,
}

// mutations
export const mutations = {
  [types.SET_COUNT](state, { count }) {
    state.count = count
  },
}

// actions
export const actions = {
  setCount({ commit }, { count }) {
    commit(types.SET_COUNT, { count })
    Cookies.set('count', count, { expires: 365 })
  },
}
