<template>
  <div>
    <!-- Messenger Chat plugin Code -->
    <div id="fb-root"></div>

    <!-- Your Chat plugin code -->
    <div id="fb-customer-chat" class="fb-customerchat"></div>
  </div>
</template>

<script>
export default {
  mounted() {
    if (process.env.VUE_APP_ENVIROMENT !== 'local') {
      var chatbox = document.getElementById('fb-customer-chat')
      chatbox.setAttribute('page_id', '105351764988706')
      chatbox.setAttribute('attribution', 'biz_inbox')

      window.fbAsyncInit = function () {
        // eslint-disable-next-line no-undef
        FB.init({
          xfbml: true,
          version: 'v14.0',
        })
      }
      ;(function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js'
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', 'facebook-jssdk')
    }
  },
}
</script>

<style></style>
