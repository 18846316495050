import * as types from '../mutation-types'

// state
export const state = {
  uploadedDocumentList: [],
}

// getters
export const getters = {
  uploadedDocumentList: (state) => state.uploadedDocumentList,
}

// mutations
export const mutations = {
  [types.SAVE_UPLOADED_DOCUMENT](state, { uploadedDocumentList }) {
    state.uploadedDocumentList = uploadedDocumentList
  },
}

// actions
export const actions = {
  saveUploadedDocument({ commit }, { uploadedDocumentList }) {
    commit(types.SAVE_UPLOADED_DOCUMENT, { uploadedDocumentList })
  },
}
