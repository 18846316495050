import * as types from '../mutation-types'
import Cookies from 'js-cookie'
// state
export const state = {
  appointmentDone: false,
  // appointmentDone: Cookies.get('appointmentDone') ?? false
}

// getters
export const getters = {
  appointmentDone: (state) => state.appointmentDone,
}

// mutations
export const mutations = {
  [types.SET_APPOINTMENT](state, { appointmentDone }) {
    state.appointmentDone = appointmentDone
  },
}

// actions
export const actions = {
  setAppointment({ commit }, { appointmentDone }) {
    commit(types.SET_APPOINTMENT, { appointmentDone })
    Cookies.set('appointmentDone', appointmentDone, { expires: 365 })
  },
}
