<template>
  <!-- Footer -->
  <b-container id="footer" fluid class="bg-footer p-0">
    <div class="container" fluid="lg">
      <div class="row">
        <div class="col-12 col-md-6 my-auto">
          <div class="p-4">
            <div class="row">
              <div class="col my-auto">
                <b-img :src="logo" fluid-grow alt="Sypitran Logo"></b-img>
              </div>
              <div class="col-8 col-lg-9 my-auto">
                <h5 class="text-white">{{ $t('synpitarn') }}</h5>
                <div class="small text-white">{{ $t('micro_finance') }}</div>
              </div>
            </div>
            <!-- <div class="mt-5 d-flex align-items-center">
              <i class="ri-phone-fill ri-xl text-white my-auto"></i>
              <div class="small text-white my-auto px-2">
                <span @click="dial('0984530956')">098-453-0956 </span>
              </div>
            </div>
            <div class="mt-4 d-flex align-items-center">
              <i class="ri-facebook-fill ri-xl text-white my-auto"></i>

              <a href="https://www.facebook.com/105351764988706" target="_blank">
                <span class="small text-white my-auto px-2"> Syn Pitarn </span>
              </a>
            </div> -->
          </div>
          <div class="mb-4 text-center text-lg-left">
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsynpitarn&tabs=events&width=340&height=131&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
              width="340"
              height="131"
              style="border: none; overflow: hidden"
              scrolling="no"
              frameborder="0"
              allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
        <div class="col-12 col-md-6 m-0 p-0">
          <div class="p-4 text-center">
            <h4 class="text-white">{{ $t('side_map') }}</h4>
            <router-link to="/register">
              <h6 class="mt-3 text-secondary">{{ $t('side_map_apply_loan') }}</h6>
            </router-link>
            <router-link to="/get-to-know-us">
              <h6 class="mt-3 text-secondary">{{ $t('side_map_get_to_know_us') }}</h6>
            </router-link>
            <router-link to="/service-procedure">
              <h6 class="mt-3 text-secondary">{{ $t('side_map_service_procedure') }}</h6>
            </router-link>
            <router-link to="/term-and-conditions">
              <h6 class="mt-3 text-secondary">{{ $t('term_and_condition') }}</h6>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </b-container>
  <!-- Footer -->
</template>

<script>
import linkify from 'vue-linkify'
export default {
  directives: {
    linkified: linkify,
  },
  data() {
    return {
      logo: require('@/assets/images/logo.png'),
    }
  },
  methods: {
    dial(number) {
      window.location = 'tel:' + number
    },
    goto(refName) {
      var element = this.$refs[refName]
      var top = element.offsetTop
      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style></style>
