export default function chooseLoanType({ to, next, store }) {
  const loanFormState = store.getters['auth/loanFormState']
  const routeName = to.name

  if (loanFormState !== 'additional_information') {
    if ((loanFormState === null || loanFormState === 'null') && routeName !== 'qr-scan') {
      return next({ name: 'qr-scan' })
    } else if (loanFormState === 'qr_scan' && routeName !== 'customer-information') {
      return next({ name: 'customer-information' })
    } else if (loanFormState === 'customer_information' && routeName !== 'required-documents') {
      return next({ name: 'required-documents' })
    } else if (loanFormState === 'required_documents' && routeName !== 'additional-information') {
      return next({ name: 'additional-information' })
    } else {
      return next()
    }
  }
  return next()
}
