var modal = {
  methods: {
    showAlert(msg) {
      this.$bvModal
        .msgBoxOk(msg, {
          hideTitle: true,
          size: 'sm',
          buttonSize: 'sm',
          bodyClass: 'p-4',
          okTitle: this.$t('got_it'),
          centered: true,
          scrollable: true,
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}

export default modal
