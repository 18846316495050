var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-primary"},[_c('b-navbar',{attrs:{"toggleable":"lg","type":"dark","variant":"primary container"}},[_c('b-navbar-brand',{attrs:{"href":"#"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"/"}},[_c('b-img',{staticClass:"align-middle",staticStyle:{"width":"5rem"},attrs:{"src":_vm.logo}})],1),_c('div',{staticClass:"my-auto pl-3"},[_c('h5',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.$t('synpitarn')))]),_c('h6',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.$t('micro_finance')))])])],1)]),_c('div',{staticClass:"ml-auto d-flex align-items-start align-items-lg-center"},[_c('router-link',{staticClass:"text-decoration-none mr-3",attrs:{"to":{ name: 'notification' }}},[_c('div',{staticClass:"position-relative"},[_c('i',{staticClass:"ri-notification-fill text-white ri-noti-badge"}),(_vm.$store.getters['notification/count'] > 0)?_c('div',{staticClass:"custom-small-noti-badge"},[_vm._v(" "+_vm._s(_vm.$store.getters['notification/count'] >= 100 ? '99+' : _vm.$store.getters['notification/count'])+" ")]):_vm._e()])]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}})],1),_c('b-collapse',{staticClass:"flex-grow-0",attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto d-flex align-items-start align-items-lg-center"},[_c('b-nav-item',{attrs:{"href":"#"}},[_c('router-link',{staticClass:"text-decoration-none text-white",attrs:{"to":"/home"}},[_vm._v(_vm._s(_vm.$t('header_home')))])],1),_c('b-nav-item-dropdown',{scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"nav-link"},[_vm._v(_vm._s(_vm.$t('header_profile')))])]},proxy:true}])},[_c('b-dropdown-item',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                name: 'profile',
                params: { type: 'basic-information' },
              }}},[_vm._v(_vm._s(_vm.$t('header_basic_information')))])],1),_c('b-dropdown-item',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'profile', params: { type: 'documents' } }}},[_vm._v(_vm._s(_vm.$t('header_documents')))])],1),_c('b-dropdown-item',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                name: 'profile',
                params: { type: 'additional-documents' },
              }}},[_vm._v(" "+_vm._s(_vm.$t('header_additional_document')))])],1),_c('b-dropdown-item',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'profile', params: { type: 'loan-history' } }}},[_vm._v(" "+_vm._s(_vm.$t('header_loan_history')))])],1)],1),_c('b-nav-item-dropdown',{scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"nav-link"},[_vm._v(_vm._s(_vm.$t('header_location')))])]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"/find-branch"}},[_vm._v(_vm._s(_vm.$t('header_nearest_branch')))])],1),_c('b-dropdown-item',{attrs:{"href":"#"}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"/find-atm"}},[_vm._v(_vm._s(_vm.$t('header_nearest_atm')))])],1)],1),_c('b-nav-item',{on:{"click":_vm.confirmLogout}},[_vm._v(_vm._s(_vm.$t('header_logout')))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }