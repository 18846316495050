<template>
  <div>
    <HeaderWithPhone></HeaderWithPhone>
    <div style="min-height: 100vh">
      <router-view />
    </div>
  </div>
</template>
<script>
import HeaderWithPhone from '../components/HeaderWithPhone.vue'
export default {
  components: { HeaderWithPhone },
}
</script>
<style></style>
