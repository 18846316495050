import Vue from 'vue'
import VueRouter from 'vue-router'
import guest from '@/middleware/guest.js'
import auth from '@/middleware/auth.js'
import rejection from '@/middleware/rejection.js'
import loanstate from '@/middleware/loanstate.js'
import loanform from '@/middleware/loanform.js'
import customerInformation from '@/middleware/customer-information'
import requiredDocuments from '@/middleware/requirement-documents.js'
import chooseLoanType from '@/middleware/choose-loan-type'
import interviewAppointment from '@/middleware/interview-appointment.js'
import middlewarePipeline from './middlewarePipeline'
import store from '@/store'

Vue.use(VueRouter)

function configRoutes() {
  return [
    {
      path: '/',
      name: 'landing',
      component: () => import('@/views/landing/Landing.vue'),
      meta: { layout: 'full' },
    },
    {
      path: '/get-to-know-us',
      name: 'get-to-know-us',
      component: () => import('@/views/landing/GetToKnowUs.vue'),
      meta: { layout: 'full' },
    },
    {
      path: '/service-procedure',
      name: 'service-procedure',
      component: () => import('@/views/landing/ServiceProcedure.vue'),
      meta: { layout: 'full' },
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('@/views/landing/FAQ.vue'),
      meta: { layout: 'full' },
    },
    {
      path: '/annoucements',
      name: 'annoucements',
      component: () => import('@/views/landing/Annoucements.vue'),
      meta: { layout: 'full' },
    },
    {
      path: '/term-and-conditions',
      name: 'term-and-conditions',
      component: () => import('@/views/termcondition/TermAndCondition.vue'),
      meta: { layout: 'full' },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/register/RegisterWithPhone.vue'),
      meta: { middleware: [guest], layout: 'full' },
    },
    {
      path: '/confirm-otp',
      name: 'confirm-otp',
      component: () => import('@/views/register/ConfirmOTP.vue'),
      meta: { middleware: [guest], layout: 'full' },
    },
    {
      path: '/forget-password/confirm-otp',
      name: 'forget-password-otp',
      component: () => import('@/views/register/ConfirmOTP.vue'),
      meta: { middleware: [guest], layout: 'full' },
    },
    {
      path: '/set-pincode',
      name: 'set-pincode',
      component: () => import('@/views/register/SetPinCode.vue'),
      meta: { layout: 'full' },
    },
    {
      path: '/confirm-pincode',
      name: 'confirm-pincode',
      component: () => import('@/views/register/ConfirmPinCode.vue'),
      meta: { layout: 'full' },
    },
    {
      path: '/forget-password/set-pincode',
      name: 'forget-password-set-pincode',
      component: () => import('@/views/register/SetPinCode.vue'),
      meta: { layout: 'full' },
    },
    {
      path: '/forget-password/confirm-pincode',
      name: 'forget-password-confirm-pincode',
      component: () => import('@/views/register/ConfirmPinCode.vue'),
      meta: { layout: 'full' },
    },
    {
      path: '/rejection',
      name: 'rejection',
      component: () => import('@/views/register/Rejection.vue'),
      meta: { middleware: [auth], layout: 'phone' },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/Login.vue'),
      meta: { middleware: [guest], layout: 'full' },
    },
    {
      path: '/forget-password',
      name: 'forget-password',
      component: () => import('@/views/login/ForgetPassword.vue'),
      meta: { middleware: [guest], layout: 'full' },
    },
    // {
    //   path: '/personal-detail',
    //   name: 'personal-detail',
    //   component: () => import('@/views/applyloan/PersonalDetail.vue'),
    //   meta: { middleware: [auth, rejection, loanform], layout: 'full' },
    // },
    // {
    //   path: '/employment',
    //   name: 'employment',
    //   component: () => import('@/views/applyloan/Employment.vue'),
    //   meta: { middleware: [auth, rejection, loanform], layout: 'full' },
    // },
    // {
    //   path: '/financial',
    //   name: 'financial',
    //   component: () => import('@/views/applyloan/Financial.vue'),
    //   meta: { middleware: [auth, rejection, loanform], layout: 'full' },
    // },
    // {
    //   path: '/file-upload',
    //   name: 'file-upload',
    //   component: () => import('@/views/applyloan/FileUpload.vue'),
    //   meta: { middleware: [auth, rejection, loanform], layout: 'full' },
    // },
    {
      path: '/interview-appointment',
      name: 'interview-appointment',
      component: () => import('@/views/interview/InterviewAppointment.vue'),
      meta: { middleware: [auth, rejection, loanform, interviewAppointment], layout: 'phone' },
    },
    {
      path: '/appointment-success',
      name: 'appointment-success',
      component: () => import('@/views/interview/AppointmentSuccess.vue'),
      meta: { middleware: [auth, rejection], layout: 'default' },
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/account/Home.vue'),
      meta: { middleware: [auth, rejection, loanstate], layout: 'default' },
    },
    {
      path: '/current-loan',
      name: 'current-loan',
      component: () => import('@/views/loandetail/CurrentLoan.vue'),
      meta: { middleware: [auth, rejection], layout: 'default' },
    },
    {
      path: '/loan-history/:id',
      name: 'loan-history-detail',
      component: () => import('@/views/loandetail/LoanHistoryDetail.vue'),
      meta: { middleware: [auth, rejection], layout: 'default' },
    },
    {
      path: '/profile/:type',
      name: 'profile',
      component: () => import('@/views/profile/Profile.vue'),
      meta: { middleware: [auth], layout: 'default' },
    },
    {
      path: '/edit-information',
      name: 'edit-information',
      component: () => import('@/views/profile/EditBasicInformation.vue'),
      meta: { middleware: [auth], layout: 'default' },
    },
    {
      path: '/pre-approve/make-appointment',
      name: 'pre-approve-make-appointment',
      component: () => import('@/views/preapprove/PreApproveMakeAppointment.vue'),
      meta: { middleware: [auth], layout: 'default' },
    },

    {
      path: '/notification',
      name: 'notification',
      component: () => import('@/views/notification/Notification.vue'),
      meta: { middleware: [auth], layout: 'default' },
    },
    {
      path: '/payment/:type',
      name: 'payment',
      component: () => import('@/views/payment/Payment.vue'),
      meta: { middleware: [auth, rejection], layout: 'default' },
    },
    {
      path: '/find-atm',
      name: 'find-atm',
      component: () => import('@/views/payment/FindAtm.vue'),
      meta: { middleware: [auth], layout: 'default' },
    },
    {
      path: '/find-branch',
      name: 'find-branch',
      component: () => import('@/views/payment/FindBranch.vue'),
      meta: { middleware: [auth], layout: 'default' },
    },
    {
      path: '/change-phone-number',
      name: 'change-phone-number',
      component: () => import('@/views/changephone/ChangePhoneNumber.vue'),
      meta: { middleware: [auth], layout: 'full' },
    },
    {
      path: '/change-phone-number/confirm-otp',
      name: 'change-phone-number-confirm-otp',
      component: () => import('@/views/changephone/ConfirmOTP.vue'),
      meta: { middleware: [auth], layout: 'full' },
    },
    {
      path: '/update-information',
      name: 'update-information',
      component: () => import('@/views/account/update_information/UpdateInformation.vue'),
      meta: { middleware: [auth], layout: 'default' },
    },
    {
      path: '/resubmit-interview-appointment',
      name: 'resubmit-interview-appointment',
      component: () => import('@/views/account/update_information/InterviewAppointment.vue'),
      meta: { middleware: [auth], layout: 'default' },
    },
    {
      path: '/qr-scan',
      name: 'qr-scan',
      component: () => import('@/views/qrscan/QrScan.vue'),
      meta: { middleware: [auth, rejection, loanform], layout: 'phone' },
    },
    {
      path: '/customer-information',
      name: 'customer-information',
      component: () => import('@/views/applyloan/CustomerInformation.vue'),
      meta: { middleware: [auth, rejection, loanform, customerInformation], layout: 'phone' },
    },
    {
      path: '/required-documents',
      name: 'required-documents',
      component: () => import('@/views/applyloan/RequiredDocuments.vue'),
      meta: { middleware: [auth, rejection, loanform, requiredDocuments], layout: 'phone' },
    },
    {
      path: '/additional-information',
      name: 'additional-information',
      component: () => import('@/views/applyloan/AdditionalInformation.vue'),
      meta: { middleware: [auth, rejection, loanform], layout: 'phone' },
    },
    {
      path: '/choose-loan-type',
      name: 'choose-loan-type',
      component: () => import('@/views/applyloan/ChooseLoanType.vue'),
      meta: { middleware: [auth, rejection, loanform, chooseLoanType], layout: 'phone' },
    },
    {
      path: '/*',
      name: 'not-found',
      component: () => import('@/views/notfound/NotFound.vue'),
    },
  ]
}

const router = new VueRouter({
  mode: 'history',
  routes: configRoutes(),
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware

  const context = {
    to,
    from,
    next,
    store,
  }

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  })
})

// router.beforeEach((to, from, next) => {
//     const currentUser = Auth.isAuth();
//     const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//     if (requiresAuth && !currentUser) next('login')
//     else next()
// })

export default router
