<template>
  <div class="bg-primary">
    <b-navbar toggleable="lg" type="dark" variant="primary container">
      <b-navbar-brand href="#">
        <div class="d-flex justify-content-between align-items-center">
          <router-link class="text-decoration-none" to="/">
            <b-img class="align-middle" :src="logo" style="width: 5rem"></b-img>
          </router-link>
          <div class="my-auto pl-3">
            <h5 class="m-0">{{ $t('synpitarn') }}</h5>
            <h6 class="m-0">{{ $t('micro_finance') }}</h6>
          </div>
        </div>
      </b-navbar-brand>
      <div class="ml-auto d-flex align-items-start align-items-lg-center">
        <router-link class="text-decoration-none mr-3" :to="{ name: 'notification' }">
          <div class="position-relative">
            <i class="ri-notification-fill text-white ri-noti-badge"></i>
            <div v-if="$store.getters['notification/count'] > 0" class="custom-small-noti-badge">
              {{ $store.getters['notification/count'] >= 100 ? '99+' : $store.getters['notification/count'] }}
            </div>
          </div>
        </router-link>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      </div>
      <b-collapse id="nav-collapse" class="flex-grow-0" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto d-flex align-items-start align-items-lg-center">
          <b-nav-item href="#">
            <router-link class="text-decoration-none text-white" to="/home">{{ $t('header_home') }}</router-link>
          </b-nav-item>

          <b-nav-item-dropdown>
            <template #button-content>
              <div class="nav-link">{{ $t('header_profile') }}</div>
            </template>
            <b-dropdown-item
              ><router-link
                class="text-decoration-none"
                :to="{
                  name: 'profile',
                  params: { type: 'basic-information' },
                }"
                >{{ $t('header_basic_information') }}</router-link
              ></b-dropdown-item
            >
            <b-dropdown-item
              ><router-link class="text-decoration-none" :to="{ name: 'profile', params: { type: 'documents' } }">{{
                $t('header_documents')
              }}</router-link></b-dropdown-item
            >
            <b-dropdown-item
              ><router-link
                class="text-decoration-none"
                :to="{
                  name: 'profile',
                  params: { type: 'additional-documents' },
                }"
              >
                {{ $t('header_additional_document') }}</router-link
              ></b-dropdown-item
            >
            <b-dropdown-item
              ><router-link class="text-decoration-none" :to="{ name: 'profile', params: { type: 'loan-history' } }">
                {{ $t('header_loan_history') }}</router-link
              ></b-dropdown-item
            >
          </b-nav-item-dropdown>

          <b-nav-item-dropdown>
            <template #button-content>
              <div class="nav-link">{{ $t('header_location') }}</div>
            </template>
            <b-dropdown-item href="#"
              ><router-link class="text-decoration-none" to="/find-branch">{{
                $t('header_nearest_branch')
              }}</router-link></b-dropdown-item
            >
            <b-dropdown-item href="#"
              ><router-link class="text-decoration-none" to="/find-atm">{{
                $t('header_nearest_atm')
              }}</router-link></b-dropdown-item
            >
          </b-nav-item-dropdown>

          <b-nav-item @click="confirmLogout">{{ $t('header_logout') }}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require('@/assets/images/logo.png'),
      langs: [
        {
          name: 'Myanmar',
          short_term: 'mm',
          image_path: require('@/assets/images/myanmar.png'),
        },
        {
          name: 'English',
          short_term: 'en',
          image_path: require('@/assets/images/english.png'),
        },
        {
          name: 'Thai',
          short_term: 'th',
          image_path: require('@/assets/images/thailand.png'),
        },
      ],
      count: 0,
    }
  },
  computed: {
    locale() {
      return this.$store.getters['lang/locale']
    },
    currentImage() {
      return require('@/assets/images/' + this.locale + '.png')
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    changeLanguage(lang) {
      this.$store.dispatch('lang/setLocale', {
        locale: lang.short_term,
      })
    },
    handleScroll() {
      // this.$refs.dropdown.hide(true);
    },
    confirmLogout() {
      this.$bvModal
        .msgBoxConfirm(this.$t('confirm_logout'), {
          hideTitle: true,
          size: 'sm',
          buttonSize: 'sm',
          bodyClass: 'p-4',
          okTitle: this.$t('ok_logout'),
          cancelVariant: 'outline-primary',
          cancelTitle: this.$t('cancel_logout'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.logout()
          }
        })
    },
    logout() {
      this.$store.dispatch('auth/logout')
      this.$store.dispatch('register/reset')
      this.$router.push({ name: 'landing' })
    },
  },
}
</script>

<style>
.nav-link {
  color: white !important;
}
</style>
