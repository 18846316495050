import Vue from 'vue'
import moment from 'moment'

Vue.filter('DateFormat', function (value) {
  if (!value) return '0'
  const dArr = value.split('-') // ex input "2010-01-18"
  return dArr[2] + '-' + dArr[1] + '-' + dArr[0]
})

Vue.filter('DateFormatter', function (dateStr) {
  if (!dateStr) return ''
  var date = moment(dateStr).format('DD MMM YYYY')
  return date
})

Vue.filter('TimeFormatter', function (timeStr) {
  if (!timeStr) return ''
  var date = moment(timeStr, 'hh:mm:ss').format('h:mm A')
  return date
})

Vue.filter('Currency', function (value) {
  if (!value) return '0'
  // return Number(value).toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  return value
})

Vue.filter('AddSixMonth', function (dateStr) {
  var currentDate = moment(dateStr)
  var futureMonth = moment(currentDate).add(6, 'M')
  var futureMonthEnd = moment(futureMonth).endOf('month')

  if (currentDate.date() != futureMonth.date() && futureMonth.isSame(futureMonthEnd.format('YYYY-MM-DD'))) {
    futureMonth = futureMonth.add(1, 'd')
  }

  return futureMonth.format('DD MMMM YYYY')
})
