export default function customerInformation({ to, next, store }) {
  const loanFormState = store.getters['auth/loanFormState']
  const routeName = to.name

  if (loanFormState !== 'qr_scan') {
    if (
      (loanFormState === null || loanFormState === 'null' || loanFormState === 'qr_scan') &&
      routeName !== 'qr-scan'
    ) {
      return next({ name: 'qr-scan' })
    } else {
      return next()
    }
  }
  return next()
}
