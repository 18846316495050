<template>
  <b-navbar toggleable="lg" type="dark" variant="primary">
    <div class="container justify-content-lg-end">
      <div class="mr-4 d-flex flex-column justify-content-center" style="min-height: 68px">
        <div class="text-white font-weight-bold">{{ name }}</div>
        <div class="text-white font-weight-normal">{{ phone }}</div>
      </div>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" class="flex-grow-0" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto d-flex align-items-start align-items-lg-center">
          <b-nav-item @click="confirmLogout">{{ $t('header_logout') }}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>
<script>
import AccountService from '@/api/service/AccountService'
export default {
  name: 'HeaderWithPhone',
  components: {},
  data() {
    return {
      isBusy: false,
      name: '',
      phone: '',
    }
  },
  watch: {
    $route(to, from) {
      this.getUserProfile()
    },
  },
  created() {
    this.getUserProfile()
  },
  methods: {
    async getUserProfile() {
      try {
        this.isBusy = true
        const res = await AccountService.getUserProfile()
        this.isBusy = false
        if (res.status == 200) {
          const profileResponse = res.data.data
          this.name = profileResponse.name
          this.phone = profileResponse.phone_number
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    confirmLogout() {
      this.$bvModal
        .msgBoxConfirm(this.$t('confirm_logout'), {
          hideTitle: true,
          size: 'sm',
          buttonSize: 'sm',
          bodyClass: 'p-4',
          okTitle: this.$t('ok_logout'),
          cancelVariant: 'outline-primary',
          cancelTitle: this.$t('cancel_logout'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.logout()
          }
        })
    },
    logout() {
      this.$store.dispatch('auth/logout')
      this.$store.dispatch('register/reset')
      this.$router.push({ name: 'landing' })
    },
  },
}
</script>
<style></style>
